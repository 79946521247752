<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-flex xs12>
      <v-card class="pa-3 expande-horizontal wrap">
        <v-flex xs12>
          <v-list-item class="fonte pa-0 ma-0">
            <v-btn @click="close" icon>
              <v-icon class="mr-3" :color="$theme.secondary"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
            <v-list-item-content>
              <v-list-item-title>
                Vamos localizar sua loja no mapa
              </v-list-item-title>
              <v-list-item-subtitle>
                informe o endereço abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
        <v-flex xs12>
          <v-divider></v-divider>
        </v-flex>
        <v-flex class="pt-6" xs12>
          <div class="expande-horizontal wrap">
            <v-flex xs12>
              <v-form ref="formFindStore">
                <div class="expande-horizontal">
                  <v-flex xs4>
                    <v-select
                      label="Tipo"
                      :items="[
                        'Rua',
                        'Avenida',
                        'Alameda',
                        'Travessa',
                        'Rodovia'
                      ]"
                      v-model="type_address"
                      placeholder="ex: Avenida"
                      dense
                      :rules="[v => !!v || 'preencha aqui']"
                      class="fonte"
                      :color="$theme.secondary"
                    ></v-select>
                  </v-flex>
                  <v-flex xs8 class="pl-2">
                    <v-text-field
                      label="Endereço"
                      v-model="address"
                      placeholder="ex: Dr braulino"
                      dense
                      :rules="[v => !!v || 'preencha aqui']"
                      class="fonte"
                      :color="$theme.secondary"
                    ></v-text-field>
                  </v-flex>
                </div>
                <div class="pt-3 pb-6 expande-horizontal">
                  <v-flex class="pr-2" xs8>
                    <v-text-field
                      label="Cidade"
                      v-model="city"
                      placeholder="ex: Santana"
                      dense
                      :rules="[v => !!v || 'preencha aqui']"
                      class="fonte"
                      :color="$theme.secondary"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      label="Número"
                      v-model="number"
                      placeholder="ex: 3210"
                      dense
                      :rules="[v => !!v || 'preencha aqui']"
                      class="fonte"
                      :color="$theme.secondary"
                    ></v-text-field>
                  </v-flex>
                </div>
              </v-form>
            </v-flex>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-btn
            :disabled="loading"
            @click="searchAddress"
            class="elevation-0 fonte white--text"
            :color="$theme.secondary"
            >Verificar</v-btn
          >
        </v-flex>
        <v-flex xs12>
          <v-list v-if="addresses.length > 0" class="fonte" dense>
            <v-list-item
              :key="`address-list-${index}`"
              v-for="(address, index) in addresses"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ address.formatted_address }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle v-if="location.length > 0">
                  {{ location[0] }} {{ location[1] }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  :color="$theme.secondary"
                  dark
                  @click="selectAddress"
                  class="fonte font-weight-bold"
                  small
                  >Selecionar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  props: ["saveStoreLocation"],
  data() {
    return {
      address: "",
      number: "",
      type_address: "",
      address_store: "",
      opened: false,
      loading: false,
      addresses: [],
      city: ""
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    async searchAddress() {
      if (!this.$refs.formFindStore.validate()) {
        this.addresses = [];
        return;
      }

      try {
        this.loading = true;

        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              address: `${this.type_address} ${this.address}, ${this.city}, ${this.number}`,
              key: "AIzaSyAa5Gv7Du2ezz8fw6SHHY82vyDl7vIKO8U"
            }
          }
        );

        this.addresses = response.data.results;
        if (response.data.results.length === 1) {
          this.selectAddress();
        } else {
          this.createGlobalMessage({
            type: "success",
            message: "Refine a busca ou selecione abaixo o mais próximo!",
            timeout: 4000
          });
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    selectAddress() {
      this.saveStoreLocation(this.addresses[0]);
      this.close();
    },
    open() {
      this.opened = false;

      this.address = "";
      this.number = "";
      this.type_address = "";
      this.address_store = "";
      this.opened = false;
      this.loading = false;
      this.addresses = [];
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
};
</script>
