<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-flex xs12>
      <v-card class="pa-3 expande-horizontal wrap">
        <v-flex xs12>
          <v-list-item class="fonte pa-0 ma-0">
            <v-btn @click="cancelCreateArea" icon>
              <v-icon class="mr-3" :color="$theme.secondary"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
            <v-list-item-content>
              <v-list-item-title>
                Informações da área
              </v-list-item-title>
              <v-list-item-subtitle>
                Informe abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
        <v-flex xs12>
          <div class="expande-horizontal wrap">
            <v-flex xs12>
              <v-form ref="formNewArea">
                <v-flex xs12>
                  <v-text-field
                    label="Descrição da área"
                    v-model="info.description"
                    placeholder="ex: Bairro Zerão"
                    outlined
                    dense
                    class="fonte"
                    :color="$theme.secondary"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Tempo de entrega"
                    v-model="info.time_delivery"
                    placeholder="ex: 30 a 50 minutos"
                    outlined
                    dense
                    class="fonte"
                    :color="$theme.secondary"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  style="background: #f2f2f2; border-radius: 6px;"
                  class="mt-2 mb-2 pa-6 pb-2"
                  v-if="area_type === 'by_km'"
                  xs12
                >
                  <div class="expande-horizontal centraliza">
                    <h4 class="pb-3 fonte">Área de entrega</h4>
                  </div>
                  <div class="expande-horizontal centraliza">
                    <v-text-field
                      label="Faixa de início"
                      v-model="info.init_km"
                      type="number"
                      placeholder="ex: 0"
                      hint="0 = localização da loja"
                      dense
                      class="fonte"
                      filled
                      :rules="[v => !!v || 'preencha este campo']"
                      :color="$theme.secondary"
                    ></v-text-field>
                    <span class="fonte fonteMini font-weight-bold mx-6"
                      >até</span
                    >
                    <v-text-field
                      label="Faixa do fim"
                      v-model="info.end_km"
                      type="number"
                      placeholder="ex: 2"
                      suffix="KM"
                      dense
                      :rules="[v => !!v || 'preencha este campo']"
                      class="fonte"
                      filled
                      :color="$theme.secondary"
                    ></v-text-field>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Valor da entrega"
                    v-model="info.value"
                    placeholder="ex: 10,00"
                    outlined
                    prefix="R$"
                    dense
                    v-mask="['##']"
                    class="fonte"
                    :color="$theme.secondary"
                  ></v-text-field>
                </v-flex>
              </v-form>
            </v-flex>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-btn
            @click="calcArea"
            class="elevation-0 fonte white--text"
            :color="$theme.secondary"
            >Salvar</v-btn
          >
        </v-flex>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
// import * as turf from "@turf/turf";
export default {
  props: ["saveArea", "addressStore"],
  data() {
    return {
      opened: false,
      area: [],
      area_type: "",
      info: {}
    };
  },
  computed: {
    address() {
      if (this.addressStore.geometry) {
        const geometry = this.addressStore.geometry;
        return [geometry.location.lng, geometry.location.lat];
      } else {
        return false;
      }
    }
  },
  methods: {
    calcArea() {
      let payload = {
        area: this.area,
        ...this.info,
        area_type: this.area_type
      };
      if (this.area_type === "by_km") {
        const area = this.createCircularArea(
          this.address,
          parseInt(this.info.init_km),
          parseInt(this.info.end_km)
        );
        payload.area = [area];
        this.saveArea(payload);
      } else if (this.area_type === "by_area") {
        this.saveArea(payload);
      }
    },
    createCircularArea(centerCoords, radius1, radius2) {
      // cria um ponto a partir das coordenadas da posição central
      const centerPoint = turf.point(centerCoords);

      // cria um círculo de raio de kmInicial em torno do ponto central
      const circle1 = turf.circle(centerPoint, radius1, {
        steps: 64,
        units: "kilometers"
      });

      // cria um círculo de raio de kmFinal em torno do ponto central
      const circle2 = turf.circle(centerPoint, radius2, {
        steps: 64,
        units: "kilometers"
      });

      // cria um polígono que representa a área entre os dois círculos
      const polygon = turf.difference(circle2, circle1);

      return polygon;
    },
    openCreateArea(area, area_type) {
      this.area_type = area_type;
      this.area = area.features;
      // if (area_type === "by_area") {
      // }
      // if (area_type === "by_km") {
      //   this.area = {
      //     km_inicio: "",
      //     km_fim: ""
      //   };
      // }
      this.opened = true;
    },
    cancelCreateArea() {
      this.opened = false;
      this.area = [];
      this.area_type = "";
    },
    close() {
      this.opened = false;
    }
  }
};
</script>
